import { APIs } from '@paper/api-specs'
import { SchoolGridIn } from '@paper/api-specs/src/specs/spec.school'
import { Curriculum, DirPacket, Teacher } from '@paper/schema'
import { sortNumeric } from '@paper/utils'
import { useMemo } from 'react'
import { useSchoolYearContext } from '~src/schoolYearAirlock'
import rollbar from '~src/utils/rollbar'
import { useDirectoryData } from './data-directory'
import { useApiQuery } from './useApiQuery'

type UseSchoolPanelDataProp = {
  pgId?: string
  schoolId?: string
}

export type SchPacket = {
  curriculum: Curriculum
  packet: DirPacket
  teacher: Teacher
}

export const useSchoolPanelData = (props: UseSchoolPanelDataProp) => {
  const { pgId, schoolId } = props

  const qResultDir = useDirectoryData()
  // Fetch per-school data
  const qResultSch = usePerSchoolData(schoolId)

  // Resolve selected packet group
  let pg = pgId && qResultSch.data?.packetGroups.find((pg) => pg.id === pgId)
  // Put together spec to get details for packet group
  const pgSpec = useMemo(() => {
    return pg?.packets.flatMap(({ curriculumId, id: packetId }) => {
      // for each packet, grab its teachers
      const { sections } = qResultSch.data.sbc.find(
        (item) => item.curriculumId === curriculumId
      )
      const teacherIds = new Set(sections.map(({ teacher }) => teacher.id))
      return [...teacherIds].map(
        (teacherId): SchoolGridIn => ({ curriculumId, packetId, teacherId })
      )
    })
  }, [pg?.packets])

  // Fetch per-packetGroup data
  const qResultPg = usePacketGroupDetails(pgSpec)

  // Combine for easier consumption
  const qResults = [qResultDir, qResultPg, qResultSch]
  const qResultDependencyList = qResults.map((p) => p.data)

  const combined = useMemo(() => {
    if (!qResults.every((p) => p.isSuccess)) {
      return null
    }

    const curriculumMap = qResultDir.data.curriculum.map

    const byTeacherPacket: SchPacket[] = []

    for (const item of qResultPg.data) {
      const { curriculumId, packet, teacherId } = item

      const allSections = qResultSch.data.sbc.find(
        (p) => p.curriculumId === curriculumId
      ).sections
      const teacherSections = allSections.filter(
        (p) => p.teacher.id === teacherId
      )

      // We're getting a crash here #369
      // The teacherIds come from qResultsSch, and then echoed back in qResultPg with a packet
      // So I'm not sure how this was failing to hit, but I guess I'll add a check?
      if (teacherSections.length === 0) {
        rollbar.warn(`data-schoolPanel: unmatched teacherId #369`, {
          allSections,
          curriculumId,
          packet,
          teacherId,
        })
        continue
      }

      const { teacher } = teacherSections[0]
      const curriculum = curriculumMap.get(curriculumId).item

      byTeacherPacket.push({ curriculum, packet, teacher })
    }

    return byTeacherPacket
  }, qResultDependencyList)

  return { data: combined, pg, qResultPg, qResultSch }
}

/**
 * Per school data
 */
const usePerSchoolData = (schoolId: string) => {
  const { syId } = useSchoolYearContext()
  const enabled = !!schoolId

  const qResult = useApiQuery({
    apiSpec: APIs['school.listpackets'],
    queryVars: { body: { schoolId, syId } },
    queryFn: async ({ plainFetch }) => {
      let result = await plainFetch()
      sortNumeric(result.packetGroups, (item) => item.number)
      return result
    },
    useQueryProps: { enabled },
  })

  return qResult
}

/**
 * School + packet group
 */
const usePacketGroupDetails = (spec: SchoolGridIn[]) => {
  const enabled = !!spec

  const qResult = useApiQuery({
    apiSpec: APIs['school.packetgroup'],
    queryVars: { body: { spec } },
    useQueryProps: { enabled },
  })

  return qResult
}
