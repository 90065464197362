import { XOR } from '@paper/utils'
import { formatDate, startOfHour } from '@paper/utils/date'
import { orderBy } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { calcScanVelos } from './scanVelo'
import { useScanVizDelayGraphContext } from './scanVizDelayGraphProvider'
import { useScanVizContext } from './scanVizProvider'

const MS_PER_HR = 3600_000
const durationHrs = (a: number, b: number) => (a - b) / MS_PER_HR

type ScanVizDelayGraphProps = {}

export function ScanVizDelayGraph(props: ScanVizDelayGraphProps) {
  const { data } = useScanVizContext()
  const { series } = useScanVizDelayGraphContext()

  const r = 2
  const breakWidth = 5
  /** number of minutes at which we clamp */
  const ClampYMinAt = 60
  const borderThickness = 10
  const height = 260

  /** converts minute to Y coord */
  const minutesToY = (min: number) => {
    const pad = borderThickness
    const padHeight = height - 2 * pad
    const heightOfMin = padHeight / ClampYMinAt
    return height - pad - heightOfMin * min
  }

  ////////////////////////
  // Clump batches
  ////////////////////////
  const clumps = useMemo(() => produceClumps(data, 4), data)

  // Calculate clump X coords and width
  const { clumpXs, width } = useMemo(() => {
    let cursorX = breakWidth
    const clumpXs = clumps.map((c) => {
      let x = cursorX
      cursorX += breakWidth + c.lengthMin
      return x
    })
    return { clumpXs, width: cursorX }
  }, [clumps])

  ////////////////////////
  // Get velos
  ////////////////////////
  const veloMap = useMemo(() => {
    // todo: probably sorting three times, since they probably come in order, clump sorts, we sort
    const sortedBatches = orderBy(data, (p) => p.scanDate)
    const velos = calcScanVelos(sortedBatches)
    return new Map(velos.map((velo, idx) => [sortedBatches[idx].id, velo]))
  }, [data])

  ////////////////////////
  // Tick marks
  ////////////////////////
  const ticks: ReactNode[] = []
  type TickProps = XOR<{ x: number }, { y: number }> & {
    stroke?: string
    strokeOpacity?: number
    strokeWidth?: number
  }

  const tick = (props: TickProps) => {
    let { stroke = 'white', strokeOpacity = 0.2, strokeWidth = 1, x, y } = props

    // adjust x/y for stroke width
    ;[x, y] = [x, y].map((p) => {
      if (p != null) {
        p = p - strokeWidth / 2
      }
      return p
    })

    return (
      <line
        key={`${x}-${y}`}
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        strokeWidth={strokeWidth}
        x1={x ?? 0}
        x2={x ?? width}
        y1={y ?? borderThickness}
        y2={y ?? height - borderThickness}
      />
    )
  }

  // Add X ticket marks for hours
  clumps.forEach((c, clumpIdx) => {
    for (let m = 60; m < c.lengthMin; m += 60) {
      ticks.push(tick({ x: clumpXs[clumpIdx] + m }))
    }
  })

  // Add Y tick marks
  ;[10, 20, 30, 45].forEach((min) => {
    ticks.push(tick({ y: minutesToY(min) }))
  })

  ////////////////////////
  // X-axis clump breaks
  ////////////////////////
  const xAxisBreaks = clumpXs.slice(1).map((x) => {
    return [-breakWidth, 0].flatMap((offsetX) =>
      tick({
        x: x - offsetX,
        strokeOpacity: 0.75,
        strokeWidth: 0.75,
      })
    )
  })

  ////////////////////////
  // Border
  ////////////////////////
  ;[borderThickness, height].forEach((y) => {
    ticks.push(
      tick({
        y,
        strokeOpacity: 0.2,
        strokeWidth: borderThickness,
      })
    )
  })

  ////////////////////////
  // Datapoints
  ////////////////////////
  const datapoints = clumps.map((c, clumpIndex) => {
    const startHr = c.hours[0]
    const clumpX = clumpXs[clumpIndex]
    return (
      <g key={startHr} pointerEvents="none">
        {c.items.map((batch) => {
          return series.selected.map((key, seriesIdx) => {
            const ser = series.meta[key]
            const xMins = durationHrs(batch.scanDate, startHr) * 60
            const yMins =
              key === 'velo'
                ? veloMap.get(batch.id) / 1000 // not actually minutes, but do 1000s, since 45_000 was approx threshold
                : batch.times[key] / 60
            const clampedYMins = Math.min(ClampYMinAt, yMins)
            const fill =
              yMins > ClampYMinAt
                ? ser.color.clamp
                : yMins > ser.warnAt
                ? ser.color.warn
                : ser.color.normal

            return (
              <circle
                key={batch.id + seriesIdx}
                cx={clumpX + xMins}
                cy={minutesToY(clampedYMins)}
                fill={fill}
                r={r}
              />
            )
          })
        })}
      </g>
    )
  })

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      style={{
        maxHeight: '200px',
        maxWidth: '100%',
        transformOrigin: 'top left',
      }}
    >
      <g id="ticks">{ticks}</g>
      <g id="xaxis-breaks">{xAxisBreaks}</g>
      {datapoints}
    </svg>
  )
}

type Clump<T> = { hh: string[]; hours: number[]; items: T[]; lengthMin: number }

function produceClumps<T extends { scanDate: number }>(
  items: T[],
  clumpTolerance: number
): Clump<T>[] {
  if (!items?.length) {
    return []
  }

  // Extract unique sorted hours
  const sortedItems = items
    .map((item) => ({
      hour: startOfHour(new Date(item.scanDate)).valueOf(),
      item,
    }))
    .sort((a, b) => a.hour - b.hour)

  const clumps: Clump<T>[] = []
  let curClump: Clump<T>

  for (let { hour, item } of sortedItems) {
    const lastHour = curClump?.hours.at(-1) ?? NaN
    // Calculate the gap in hours between the last hour in the current clump and the next hour
    const gapInHours = durationHrs(hour, lastHour)

    // Add new clump if no clump or gap exceeds tolerance
    if (!curClump || gapInHours > clumpTolerance) {
      curClump = { hh: [], hours: [], items: [], lengthMin: 0 }
      clumps.push(curClump)
    }

    curClump.hours.push(hour)
    curClump.items.push(item)
  }

  // Uniquify hours and fill in hh for debugging
  clumps.forEach((c) => {
    c.hours = Array.from(new Set(c.hours))
    c.hh = c.hours.map((hr) => formatDate(hr, `MM-dd'T'HH`))
    c.lengthMin = (durationHrs(c.hours.at(-1), c.hours[0]) + 1) * 60
  })

  return clumps
}
